import axios from "../../utils/axios";

//
export function groupInfoList(params) {
    return axios({
        url: "/admin/groupInfo/list",
        method: "get",
        params: params
    });
}

//
export function groupInfoSave(data, formName, method = "post") {
    let url =
        formName === "add"
            ? "/admin/groupInfo/add"
            : "/admin/groupInfo/update";
    return axios({
        url: url,
        method: method,
        data: data
    });
}

//
export function groupInfoDelete(data) {
    return axios({
        url: "/admin/groupInfo/delete",
        method: "post",
        data: data
    });
}
